import React from 'react'
import { Link } from 'react-router-dom'

export default function Resume() {
  
  return (
    <div className='bg-slate-900 grid md:grid-cols-10 pt-16 text-gray-50 pb-12 font-sans'>

        <div className='flex-wrap justify-center md:col-start-1 md:col-span-3'>
          <h1 className='text-4xl font-semibold p-1 pl-3 md:pl-8 mb-4 text-left'>Billy Best</h1>
          <div className='p-1 pl-3 md:pl-8 text-left'><p>+1 604 700 7524</p></div>
          <div className='p-1 pl-3 md:pl-8 text-left'><p><a href='mailto:bill@maasdao.io'>bill@tsuga.digital</a></p></div>
          <div className='p-1 pl-3 md:pl-8 text-left'><p><a href='https://github.com/iFrogHop2Worlds'>github.com/iFrogHop2Worlds</a></p></div>

          <div className='mb-2 mt-3 p-3 md:p-8 text-left'>
            <p>
              I am a fullstack engineer. I primarily work in Rust and Javascript making ecommerce and business tools.
              I recently rewrote several Node backends in Rust reducing server costs by 60-80% and 10x'ing performance in most cases.
            </p>
          </div>

          <div className='p-3 hidden md:inline-block'>
            <h2 className='text-white text-2xl'>Certifications</h2>
            <p>M220JS: MongoDB for JavaScript Developers</p>
            <p className='text-md font-light'><a href='https://university.mongodb.com/course_completion/3ffe1524-40e9-4fc9-90b0-82d0e0b2d99a?
            utm_source=copy&utm_medium=social&utm_campaign=university_social_sharing'>certificate link</a></p>

            <p>M121: MongoDB Aggregation Framework</p>
            <p className='text-md font-light'><a href='https://university.mongodb.com/course_completion/850b51a0-5bc3-4eb4-912b-ea6e39433cdb?
            utm_source=copy&utm_medium=social&utm_campaign=university_social_sharing'>certificate link</a></p>

            <p>M001: MongoDB Basics</p>
            <p className='text-md font-light'><a href='https://university.mongodb.com/course_completion/aa16fe03-3da8-4e0f-a60e-6971a84ef0d2?
            utm_source=copy&utm_medium=social&utm_campaign=university_social_sharing'>certificate link</a></p>

            <p>JavaScript Data Structures and Algorithms</p>
            <p className='text-md font-light'><a href='https://freecodecamp.org/certification/illbill/javascript-algorithms-and-data-structures'>certificate link</a></p>
            
            <p>Portswigger/Burpsuite cert coming soon</p>
          </div>
        </div>

      <div className='md:col-start-4 md:col-span-5 p-3 text-left'>
        <h3 className='bg-emerald-400 text-xl text-center font-semibold m-1 mt-6 mb-2 pt-2 pb-2 shadow-md shadow-black text-black'>Work Experience</h3>  
        <div className='flex mt-2 mb-3'>
          <p className='font-thin text-white pr-2 underline'>Freelance web development</p>
          <i>January 2021 to Present</i>
        </div>
        <p className='text-left'>
        Working with small to mid size companies I have built dozens of e-commerce websites from the ground up and built new functionality for existing websites.
        I have worked extensively in both front and back ends implementing custom themes and user flows, user authentication, backend dashboards(cms, analytics, tools), 
        stripe/paypal payment processing, formatting data and output csv files, etc. Following best practices, ensuring availability, optimizing for SEO and performance.   
        Technologies used range from php/wordpress, javascript(react/next, node), rust.
        </p>

        <div className='flex mt-2 mb-3'>
          <p className='font-thin text-white pr-2 underline'>Web technology consultant</p>
          <i>January 2023</i>
        </div>
        <p className='text-left'> Working with a cannabis dispensary I used my tech knowledge and experience to achieve particular goals. The client and I worked together
        discovering their needs which I then addressed in a technical analysis of their digital infrastructure. Offering an array of solutions 
        based on cost, time, and problem coverage. I made a development roadmap which could be tweaked based on the trade offs your willing to make. 
        This gave the client a clear understanding and path to take to scaling and streamlining the processes required.
        </p>

        <h3 className='bg-emerald-400 text-xl text-center font-semibold m-1 mt-6 mb-2 pt-2 pb-2 shadow-md shadow-black text-black'>Personal Projects</h3>
        <div className='flex mt-2 mb-3'>
          <p className='font-thin text-white pr-2 underline'>Friends Os</p>
          <i>March 2024</i>
        </div>
        <p className='text-left'>
        This is an open source project I started that aims to be a platform for groups of people to communicate and collaborate. At the moment it supports realtime text chat, 
        creating groups, automated db backups and client seeds. With more to come in the future. The chat server is written in Rust and the initial web client is written in 
        Javascript. <a href='https://github.com/iFrogHop2Worlds/friends-os'>
          github repo</a>
        </p>
       
        <hr className='mt-2 pb-2'/>
        <div className='flex mt-2 mb-3'>
          <p className='font-thin text-white pr-2 underline'>Inventory reporting system</p>
          <i>current</i>
        </div>
        <p className='text-left'> 
        This is a saas product I am working on which has a multi tiered users system and allows the master admin to deploy store instances, store admin to manage store instance by
        creating employee accounts, starting sessions (offering concurent sessions opening up some powerful features), all users can use the barcode scanner and participate in an
        inventory session. I built a prototype of the front end in react a few months ago which has a working barcode scanner and basic (crud) interface for managing scanned items. 
        I am currently building the backend in Rust. Leveraging multithreading, asynchronous code, pessimistic updates for a fast reliable service.  Demo coming soon.
        </p>
        <hr className='mt-2 pb-2'/>
        <div className='flex mt-2 mb-3'>
          <p className='font-thin text-white pr-2 underline'>light-weight ecommerce solution</p>
          <i>current</i>
        </div>
        <p className='text-left'> 
        This project was inspired from my experience working with ecommerce platforms such as woocommerce, shopify and squarespace. I found these platforms
        to be restrictive and clunky so I built my own light weight alternative. Including all the features a business needs to grow 
        and excell with CRM, Sales/Promotions tools, CMS, Payment instruments, data analysis and more. Bundled into a clean user interface  
        </p>
        
        <hr className='mt-2 pb-2'/>

        
        <h3 className='bg-emerald-400 text-xl text-center font-semibold m-1 mt-6 mb-2 pt-2 pb-2 shadow-md shadow-black text-black'>Education</h3>
        <p className='font-thin text-white underline mb-2'>Self taught in Computer Science, Software Engineering</p>
        <p className='text-left'>
          I studied data structures & algorithms, computer networking, operating systems, compilers, cyber security, mathematics and game theory. A few of the books I 
          worked through are "Grokkings DSA", "Computer networking: a top down approach", "Compilers: Principles, Techniques, and Tools" 
          "Hands-On Machine Learning with Scikit-Learn & TensorFlow", to name a few. I listened and worked through the MIT 6.006 Introduction to Algorithms lectures.
           I have thousands of hours speant reading documentation for various languages, libraries and frameworks. Recently read the "Rust", "async-rust", "Rust Atomics and Locks" books.
        </p>

        <h3 className='bg-emerald-400 text-xl text-center font-semibold m-1 mt-6 mb-2 pt-2 pb-2 shadow-md shadow-black text-black'>Additional Information</h3>
        <p className='text-left'>
        I come from a construction background which I think sets me apart from your average devloper. I love a good challenge, learning and building cool things
        that give people value. I completed the Hacktoberfest challenge in 2020. In 2021 I did two crypto hackathons for Chainlink and Avalanche projects.
        Other then coding some hobbies/interest I have include hiking, camping, fishing, skateboarding. 
  
        </p>   
      </div>


      <div className='md:col-start-9 md:col-span-2 text-md p-3'>
            <h6 className='text-xl text-white mb-1 underline'>Languages</h6>
            <p className='text-orange-300'>Rust</p>
            <p className='text-white'>C++</p>
            <p className='text-green-400'>Python</p>
            <p className='text-purple-800'>Solidity</p>
            <p className='text-yellow-400'>Javascript</p>
            <p className='text-blue-100'>TypeScript</p>
            <p className='text-orange-200'>PHP</p>
            <p className='text-blue-200'>html</p>
            <p className='text-purple-200'>css</p>
            <h6 className='text-xl text-white mb-1 underline'>Database</h6>
            <p className='text-green-200'>MongoDB</p> 
            <p className='text-sky-200'>PostgreSQL</p> 
            <h6 className='text-xl text-white mb-1 underline'>Frameworks</h6>
            <p className='font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-100'>Tailwind css</p>
            <p className='text-green-500'>Express</p>
            <p className='text-white'>Actix-web</p>
            <p className='text-transparent bg-clip-text bg-gradient-to-r from-orange-300 to-red-500'>Rocket</p>
            <p className='text-blue-900'>React/Next</p>
            <p className='text-green-900'>Node.js (runtime)</p>
            <p className='text-purple-200'>Brownie (smart contracts)</p>
            <p className='text-rose-100'>WordPress</p> 
            <h6 className='text-xl text-white mb-1 underline'>Tools</h6>
            <p className='text-lime-100'>Burp Suite</p>
            <p className='text-slate-500'>Git</p>
            <p className='text-indigo-400'>Linux/Kali Linux</p>
            <p className='text-emerald-100'>Windows</p>
            <p className='text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-cyan-500'>Digital Ocean</p>
            <p className='text-red-900'>Adobe Photoshop</p>
            <p className='text-sky-700'>Sony Vegas</p>
            <p className='text-lime-400'>Google'ing</p>
            <h6 className='mt-4 text-xl text-white mb-1 underline'>Learning</h6>
            <p className='text-gray-100'>Ethical hacking</p>
          

            <div className='p-3 inline-block md:hidden'>
              <h2 className='mt-8 text-white text-2xl'>Certifications</h2>
              <p>M220JS: MongoDB for JavaScript Developers</p>
              <p className='text-md font-light'><a href='https://university.mongodb.com/course_completion/3ffe1524-40e9-4fc9-90b0-82d0e0b2d99a?
              utm_source=copy&utm_medium=social&utm_campaign=university_social_sharing'>certificate link</a></p>

              <p>M121: MongoDB Aggregation Framework</p>
              <p className='text-md font-light'><a href='https://university.mongodb.com/course_completion/850b51a0-5bc3-4eb4-912b-ea6e39433cdb?
              utm_source=copy&utm_medium=social&utm_campaign=university_social_sharing'>certificate link</a></p>

              <p>M001: MongoDB Basics</p>
              <p className='text-md font-light'><a href='https://university.mongodb.com/course_completion/aa16fe03-3da8-4e0f-a60e-6971a84ef0d2?
              utm_source=copy&utm_medium=social&utm_campaign=university_social_sharing'>certificate link</a></p>

              <p>JavaScript Data Structures and Algorithms</p>
              <p className='text-md font-light'><a href='https://freecodecamp.org/certification/illbill/javascript-algorithms-and-data-structures'>certificate link</a></p>
              
              <p>Portswigger/Burpsuite cert coming soon</p>
          </div>
      </div>
  </div>
  )
}
