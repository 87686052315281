import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

export default function ProjectGallery() {
    const [items,  setItems] = useState([]);
    const [filtered,  setFiltered] = useState([]);

    const getReq = async () => {
        setItems([]);
        await axios.get(`https://billsdev.space:7500/projects/all`).then(res => {
            setItems(res.data);
            setFiltered(res.data.reverse());
        }); // 138.197.151.61
    }

    // we need to check values in our projects against our text input and return only the matches
    const textSearch = (e) => {
         e.preventDefault();
        let value = e.target.value.toLowerCase()
        setFiltered(items.filter(project => project.title.toLowerCase().includes(value)));
    }

    useEffect(() => {
        getReq()
    }, []);

    return (
        <> 
        <div className='bg-slate-900'>
            {/* filter / search bar */}
            <div className='flex justify-center pt-16 pb-8'>
                <p className='mr-3 text-white'>search</p>
                <input className='search-bar' type="search" id="search" data-search onChange={textSearch}></input>
            </div>
            {/* grid view of all projects */}
           <div className='grid grid-flow-row-dense grid-cols-1 md:grid-cols-3 lg:grid-cols-4 grid-rows-auto place-items-center pb-12'>
                {   
                    items.length > 0 &&
                    filtered.map(projects => {
                        return (
                            <div className='p-3 m-1 border border-r-2 border-cyan-400 bg-zinc-300'>
                                <Link to='/project-single' state={{ name: `${projects.title}`, image: `${projects.image}`, demo: `${projects.demo}`, repository: `${projects.repository}`, description: `${projects.description}` }}>           
                                    <img src={projects.image} alt='project display' className='w-96 h-72'/>
                                    <p className='text-slate-950'>{projects.title}</p>
                                </Link>
                            </div>
                        )
                    })   
                } 
           </div>

        </div>  
        </>
    )
}
