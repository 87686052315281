import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
 import getReq from '../../helpers/getReq'
export default function Footer() {

    const [faq, setFaq] = useState([]);
    let i = Math.floor(Math.random() * faq.length);
    useEffect(() => {
        getReq(`http://138.197.151.61:7500/user-api/get-questions`)
        .then((res) => {
            setFaq(res);
        })
        
    }, []);

  return (
        <div className='bg-black text-white grid md:grid-cols-2 pb-32 z-50'>
            <div className='md:col-start-1 md:col-span-1 text-left  mt-12 px-4 md:pl-12'>
                <Link to={'/FAQs'}><h3 className='text-center'>FAQ's</h3></Link>
                { faq.length > 1 &&
                    <>
                        <p>Random question from:  {faq[i].name?faq[i].name: "anonymous"}</p>
                        <div className='flex'>
                            <p className='font-bold pr-1'>Q: </p><p>{faq[i].question?faq[i].question: "error: corrupted data"}</p>
                        </div>               
                        <div className='flex'>
                            <p className='font-bold pr-1'>A: </p><p>{faq[i].reply?faq[i].reply: "no reply yet"}</p>
                        </div>
                    </>
                }   
            </div>

            <div className='md:flex md:justify-around place-items-center md:col-start-2 md:col-span-1'>
                <div className='mt-12'>
                    <h5>Socials</h5>
                    <ul className='flex justify-center'>
                        <li className='p-1' ><a href='https://github.com/'>Instagram</a></li>
                        <li className='p-1'><a href='https://twitter.com/ifroghop2worlds'>Twitter</a></li>
                        <li className='p-1'><a href='https://www.youtube.com/channel/UC7mv77-nPQf1bEQhqSTnmqg'>Youtube</a></li>
                    </ul>   
                </div>

                <div className='mt-12'>
                    <h2>Affiliates</h2>
                    <ul className='flex justify-center'>
                        <li className='p-1' ><a href='#'>Maas.dao</a></li>
                        <li className='p-1'><a href='#'>Tsuga.digital</a></li>
                    </ul>  
                </div>


            </div>
        </div> 

    
  )
}
