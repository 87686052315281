import React from 'react'
import aboutmevid from '../vid/aboutmevid.mp4';

export default function AboutMe() {
  const [pageLoaded, setPageLoaded] = React.useState(false);
  React.useEffect(() => {
    setPageLoaded(true);
  }, [])

  return (
    <>
      <video className='h-screen w-full pb-96 fixed -translate-y-10 self-stretch' autoPlay loop muted>
        <source src={aboutmevid} type='video/mp4' />
      </video>
        <div className={"bg-black grid justify-items-center mx-auto pt-12 opacity-70 ease-in duration-200 " + 
          (pageLoaded === true ? 
            'translate-x-0' :
            '-translate-x-12')
            }>

            <div className='mb-4'>
                <img src='https://avatars.githubusercontent.com/u/34861777?v=4' className=' border-green-400 border-2 rounded-full lg:-translate-x-56' alt='Myself standing in the snowy mountains'></img>
            </div>
            
           <div className='text-left mb-8 lg:w-1/2  text-white overflow-scroll'>
                <p className='mb-3 p-3'>
                    I began programming in 2018 and have been doing so professionally since 2021. My secret sauce is a unique perspective, 
                    work ethic and collaboration. I harness my past experiences and couple them with some developer wizardry to provide you 
                    thoughtful well-designed programs and websites with security in mind.  
                </p>
                <p className='mb-3 p-3'>
                    Ambition drives my process and I use whatever tools are necessary for the job. I have primarily built solutions using the MERN stack. 
                    But I am currently replacing my Js backends with Rust in most cases. With React/Next I can create any UI/UX for a tailored experience
                    with speed, availability and security baked into the design.
                </p>
                <p className='mb-3 p-3'>
                    I come from a construction background and I translate alot of that experience into my software craft which makes me unique. With a hands on approach 
                    I analyse diagnose and design systems through software.    
                </p>
                <p className='mb-3 p-3'>
                  Shoot me a message <a href="mailto:bill@tsuga.digital">email</a>  
                </p>
           </div>
        </div>
    </>
  )
}
