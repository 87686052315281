import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FaLayerGroup, FaRegWindowClose } from "react-icons/fa";
const Hamburger = () => {
    const [showBurger, setShowBurger] = useState(false)

    const toggleBurger = () => {
        setShowBurger(!showBurger);
    }

    return (
        <>
            <div className={'h-screen flex items-center justify-center md:hidden ' + (showBurger !== true? 'hidden': '')}>
                <ul>
                    <li>
                        <Link to='/'>
                            <button className='p-3  text-white font-thin hover:underline' color='primary' size='sm' onClick={toggleBurger}>
                                Home
                            </button>
                        </Link>
                    </li>
                    <li>
                        <Link to='/about-me'>
                            <button className='p-3  text-white font-thin hover:underline' color='primary' size='sm' onClick={toggleBurger}>About me</button>
                        </Link>
                    </li>
                    <li>
                        <Link to='/work'>
                            <button className='p-3  text-white font-thin hover:underline' color='primary' size='sm' onClick={toggleBurger}>Work</button>
                        </Link>
                    </li>
                    <li>
                        <a href="https://github.com/iFrogHop2Worlds" target="_blank" rel="noopener noreferrer">
                            <button className='p-3  text-white font-thin hover:underline' color='warning' size='sm' onClick={toggleBurger} >Github</button>
                        </a>
                    </li>
                    <li>
                        <Link to='/resume'>
                        <button className='p-3  text-white font-thin hover:underline' color='primary' size='sm' onClick={toggleBurger}>
                        Resume
                    </button>
                        </Link>

                    </li>
                </ul>
            </div>
            <div className='top-0 absolute pl-4 pt-2 md:hidden cursor-pointer' onClick={toggleBurger}   >
                {!showBurger &&
                   <FaLayerGroup className='text-yellow-100 text-4xl' />
                }
                {showBurger &&
                    <FaRegWindowClose className='text-white text-4xl' />
                }
            </div>

        </>
    )
}

export default Hamburger