import React, {useState} from 'react'
import { Link } from 'react-router-dom'

export default function Services() {
  const [serviceContext, setServiceContext] = useState('Development');
  const [currentService, setCurrentService] = useState('new builds');

  const myServices = [
    ["new builds", "rebuild", "add features", "update tech"],
    ["pen-test", "audits", "ssl-cert"],
    ["design", "performance"]
  ]

  return (
    <div className='bg-slate-500 pt-16 pb-16'>
 
    <div className='md:mt-12 pb-12'>
      <p> Bring your ideas to reality!</p>
    </div>

    <div className='mb-12'>
      <Link to='/inquiries'><button id='navbtn'>free quote</button></Link>
    </div>

    <div className='flex justify-center mb-1'>
      <div id='service-nav-item' className={'cursor-pointer p-3  '+ (serviceContext === 'Development'? 'font-semibold text-orange-300 cursor-pointer':'')} onClick={()=> {setServiceContext('Development'); setCurrentService('new builds')}}>Development</div>
      <div id='service-nav-item' className={'cursor-pointer p-3  '+ (serviceContext === 'Audits/Security'? 'font-semibold text-orange-300 cursor-pointer':'')} onClick={()=> {setServiceContext('Audits/Security'); setCurrentService('pen-test')}}>Audit/Security</div>
      <div id='service-nav-item' className={'cursor-pointer p-3  '+ (serviceContext === 'Consultation'? 'font-semibold text-orange-300 cursor-pointer':'')} onClick={()=> {setServiceContext('Consultation'); setCurrentService('design')}}>Consultation</div>
    </div>

    <div className='grid md:grid-cols-8'>

      <div className=' md:col-start-1 md:col-span-2 mt-12 md:border-r-2 md:pr-2'>
          <ul className='md:float-right md:mr-4'>
            {
              serviceContext === "Development" ?
                myServices[0].map((services, index) => <li className='flex justify-center place-items-center' key={index} id={services} onClick={(e) => setCurrentService(e.target.id)}><i className={'mr-2 '+(services === currentService?'arrow':'hide')}></i><div id={services} className='cursor-pointer' key={index}>{services}</div></li>)
                : 
              serviceContext === "Audits/Security" ?
                myServices[1].map((services, index) => <li className='flex justify-center place-items-center' key={index} id={services} onClick={(e) => setCurrentService(e.target.id)}><i className={'mr-2 '+(services === currentService?'arrow':'hide')}></i><div id={services} className='cursor-pointer' key={index}>{services}</div></li>)
                : 
              serviceContext === "Consultation" ?
                myServices[2].map((services, index) => <li className='flex justify-center place-items-center' key={index} id={services} onClick={(e) => setCurrentService(e.target.id)}><i className={'mr-2 '+(services === currentService?'arrow':'hide')}></i><div id={services} className='cursor-pointer' key={index}>{services}</div></li>)
                : "Error"
            }
            </ul>
      </div>

      <div className='md:col-start-3 md:col-span-4 mt-8 md:pl-2'>
     
        <div className=" " hidden={serviceContext !== 'Development'?true:false}>
          <div className='' hidden={currentService !== 'new builds'?true:false}>
            <h4 className='text-2xl font-semibold mb-2'>New Builds</h4>
            <p className='text-left p-2'>Bring your ideas to life with a new website or application. We preffer to work closely with you the client as we will discover all your needs and desires together through an iterative process; Designing your website or application from the ground up with purpose behind every detail.</p>
          </div>

          <div className='' hidden={currentService !== 'rebuild'?true:false}>
            <h4 className='text-2xl font-semibold mb-2'>Rebuild</h4>
            <p className='text-left p-2'>Bring me your existing application and we will address your previous pitfalls, select a technology and remake your existing application, adding any new features if required</p>
          </div>

          <div className='' hidden={currentService !== 'add features'?true:false}>
            <h4 className='text-2xl font-semibold mb-2'>Add Features</h4>
            <p className='text-left p-2'>Need new functionality on your website or application? No worries I got you covered. Whether you have a basic website or complex application we can design and build new features for it.</p>
          </div>

          <div className='' hidden={currentService !== 'update tech'?true:false}>
            <h4 className='text-2xl font-semibold mb-2'>Upgrade Tech</h4>
            <p className='text-left p-2'>Dependencies not being supported anymore casuing complication in your app? Has it been a while and you know that days coming? If you need to do a big update but are worried about code refactors, config changes, dependency changes that could come with it don't be. Ill do it all for you.</p>
          </div>
        </div>


        <div className="" hidden={serviceContext !== 'Audits/Security'?true:false}>
        <div className='' hidden={currentService !== 'pen-test'?true:false}>
            <h4 className='text-2xl font-semibold mb-2'>Penatration Testing</h4>
            <p className='text-left p-2'>I will attempt to hack or exploit your current system</p>
          </div>

          <div className='' hidden={currentService !== 'audits'?true:false}>
            <h4 className='text-2xl font-semibold mb-2'>Audits</h4>
            <p className='text-left p-2'>Code audits, Business,systems performance & action plans</p>
          </div>

          <div className='' hidden={currentService !== 'ssl-cert'?true:false}>
            <h4 className='text-2xl font-semibold mb-2'>SSL Certification</h4>
            <p className='text-left p-2'>We setup your SSL certificate for your website for you.</p>
          </div>
        </div>


        <div className="" hidden={serviceContext !== 'Consultation'?true:false}>
          <div className='' hidden={currentService !== 'design'?true:false}>
            <h4 className='text-2xl font-semibold mb-2'>Design</h4>
            <p className='text-left p-2'>Systems, interfaces. What to consider, trade-offs, technologies.</p>
          </div>

          <div className='' hidden={currentService !== 'performance'?true:false}>
            <h4 className='text-2xl font-semibold mb-2'>Performance</h4>
            <p className='text-left p-2'>Analyze bottlenecks and how to address them</p>
          </div>
        </div>

      </div>
    </div>



        <style>{`
        .arrow {
          height: 12px;
          width: 12px;
          border: solid cyan;
          border-width: 0 3px 3px 0;
          display: inline-block;
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
        }



      `}</style>
    </div>
  )
}
